//--------- start footer Area -------------//
.footer-area {
	background-color: #162b45;
	padding: 100px 0px 20px;
	@media (max-width: 991px) {
		padding: 70px 0px 30px;
	}
	.form-control{
		background-color: transparent;
	}
	.form-group{
		margin-top: 25px;
	}
	.single-footer-widget {
		@media (max-width: 991px) {
			margin-bottom: 30px;
		}

		p{
			color: #abb2ba;
			font-size: 14px;
			line-height: 1.8;
		}

		h4 {
			margin-bottom: 23px;
			font-weight: 700;
			font-size: 24px;
			color: $white_color;
			@media (max-width: 1024px) {
				font-size: 18px;
			}
			@media (max-width: 991px) {
				margin-bottom: 15px;
			}
		}
		ul {
			li {
				margin-bottom: 10px;
				a {
					color: #555555;
					@include transform_time(0.5s);
					font-size: 15px;
					&:hover{
						color: $btn_bg;
					}
				}
				&:last-child{
					margin-bottom: 0px;
				}
			}
		}
		.form-wrap {
			margin-top: 25px;
		}
		input {
			height: 40px;
			border: none;
			width: 67% !important;
			font-weight: 400;
			padding-left: 20px;
			border-radius: 0;
			font-size: 13px;
			color: #999999;
			border: 0px solid transparent;
			font-family: $font_stack_1;
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}
		.click-btn {
			background-color: $btn_bg;
			color: $white_color;
			border-radius: 0;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			padding: 8px 20px;
			border: 0;
			font-size: 12px;
			font-weight: 400;
			font-family: $font_stack_1;
			position: relative;
			left: 0;
			&:focus {
				outline: none;
				box-shadow: none;
      }
      
      @media(max-width: 375px){
        margin-top: 10px;
      }

			@media(min-width: 400px){
				left: -50px;
			}
     }
	}
	.footer_1{
		img{
			margin-bottom: 35px;
		}
		p{
			margin-bottom: 15px;
		}
	}
	.footer_2{
		.social_icon{
			margin-top: 27px;
			a{
				color: #cccccc;
				font-size: 14px;
				margin-right: 20px;
			}
		}
	}

	.footer_3{
		.footer_img{
			.single_footer_img{
				width: 30%;
				float: left;
				margin: 1%;
				position: relative;
				z-index: 2;
				@media #{$small_mobile}{
				}
				@media #{$large_mobile}{
					width: 15%;
				}
				@media #{$tab_device}{
					width: 10%;
				}
				@media #{$medium_device}{
					width: 10%;
				}
				&:after{
					position: absolute;
					left: 0;
					top: 0;
					content: "";
					background-color: #000;
					width: 100%;
					height: 100%;
					opacity: 0;
					@include transform_time(0.5s);
				}
				i{
					text-align: center;
					position: absolute;
					top: 41%;
					margin: 0 auto;
					left: 0;
					right: 0;
					color: #fff;
					z-index: 2;
					opacity: 0;
				}
				&:hover{
					&:after{
						opacity: 0.5;
					}
					i{
						opacity: 1;
					}
				}
			}
		}
	}
	.contact_info{
		position: relative;
		margin-bottom: 20px;
		&:last-child{
			margin-bottom: 0px;
		}
		p{
			margin-bottom: 10px;
			span{
				color: #2c3033;
				font-size: 16px;
			}
		}
	}
	.btn{
		background-color: $btn_bg;
		color: $white_color;
		width: 49px;
		border-radius: 0px;
		height: 40px;
	}
	span.ti-heart {
		font-size: 12px;
		margin: 0px 2px;
	  }
	.copyright_part_text{
		border-top: 1px solid #45556a;
		padding-top: 20px;
		margin-top: 95px;
		p{
			color: #777777;
			
		}
		@media #{$small_mobile}{
			margin-top: 20px;
			text-align: center;
			p{
				font-size: 13px;
			}
		}
		@media #{$large_mobile}{
			text-align: center;
			margin-top: 20px;
			p{
				font-size: 13px;
			}
		}
		@media #{$tab_device}{
		text-align: center;
		}
		@media #{$medium_device}{
		
		}
		a{
			color: $btn_bg;
		}
	}
	.input-group{
		border: 1px solid #45556a;
		padding: 5px;
	}
}
.white_bg{
    background-color: #f7f7f7 !important;
    p{
        color: #888888 !important;
	}
	h4{
		color: #294354 !important;
	}
	.input-group{
		border: 1px solid #e0e1e3;
		padding: 5px;
	}
	.copyright_part_text{
		border-top: 1px solid #e0e1e3;
	}
}
//--------- end footer Area -------------//