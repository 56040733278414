/**************** common css start ****************/
@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,600,700|Poppins:300,800|Roboto:300i,400,500,700');
body{
    font-family: $font_stack_1;
    padding: 0;
    margin: 0;
    font-size: 14px;
}
.message_submit_form:focus{
    outline: none;
}
input:hover, input:focus{
    outline: none !important;
}
.gray_bg{
    background-color: $section_bg;
}
.section_padding {
    padding: 140px 0px;
    @media #{$medium_device}{
        padding: 80px 0px;
    }
    @media #{$tab_device}{
        padding: 70px 0px;
    }
    @media #{$small_mobile}{
        padding: 70px 0px;
    }
    @media #{$large_mobile}{
        padding: 70px 0px;
    } 
}
.single_padding_top{
    padding-top: 140px !important;
    @media #{$medium_device}{
        padding-top: 70px !important;
    }
    @media #{$tab_device}{
        padding-top: 70px !important;
    }
    @media #{$small_mobile}{
        padding-top: 70px !important;
    }
    @media #{$large_mobile}{
        padding-top: 80px !important;
    } 
}
.padding_top{
    padding-top: 140px;
    @media #{$medium_device}{
        padding-top: 80px;
    }
    @media #{$tab_device}{
        padding-top: 70px;
    }
    @media #{$small_mobile}{
        padding-top: 70px;
    }
    @media #{$large_mobile}{
        padding-top: 70px;
    } 
}
a{
    text-decoration: none;
    @include transform_time(.5s);
    &:hover{
        color: $btn_bg !important;
        text-decoration: none;
        @include transform_time(.5s);
    }
    
}

h1, h2, h3, h4, h5, h6 {
    color: $heading_color;
    font-family: $font_stack_3;
}
p{
    color: $font_1;
    font-family: $font_stack_1;
    font-size: 14px;
    margin-bottom: 0px;
    color: $font_2;
    line-height: 1.8;
}
  
h2 {
    font-size: 44px;
    line-height: 28px;
    color: $heading_color;
    font-weight: 600;
    font-family: $font_stack_3;
    @media #{$small_mobile}{
        font-size: 22px;
        line-height: 25px;
    }
    @media #{$large_mobile}{
        font-size: 24px;
        line-height: 25px;
        
    }
}
h3 {
    font-size: 24px;
    @media #{$small_mobile}{
        font-size: 20px;
        
    }
}


.blog_right_sidebar .widget_title {
    font-size: 20px;
    margin-bottom: 40px;
    font-style: inherit; 
    font-weight: 600;
    color: #2a2a2a;
}
h5 {
    font-size: 18px;
}

img {
    max-width: 100%;
}
a:focus, .button:focus, button:focus, .btn:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    @include transform_time(1s);
}
.section_bg_2{
    background-color: $section_bg_1;
}
.section_tittle{
    margin-bottom: 83px;
    @media #{$small_mobile}{
        margin-bottom: 30px;
    }
    @media #{$large_mobile}{
        margin-bottom: 30px;
    }
    @media #{$tab_device}{
        font-size: 30px;
    }
    @media #{$medium_device}{
        margin-bottom: 30px;
    }
    h2{
        font-size: 42px;
        color: $heading_color;
        font-weight: 700;
        position: relative;
        @media #{$small_mobile}{
            font-size: 25px;
            line-height: 35px;
        }
        @media #{$large_mobile}{
            font-size: 25px;
            line-height: 35px;
        }
        @media #{$tab_device}{
            font-size: 30px;
            line-height: 40px;
        }
        @media #{$medium_device}{
            font-size: 35px;
            line-height: 40px;
        }
    }
    p{
        color: $btn_bg;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 17px;
        text-transform: uppercase;
        line-height: 14px;
        @media #{$small_mobile}{
            margin-bottom: 10px;
        }
        @media #{$large_mobile}{
            margin-bottom: 10px;
        }
        @media #{$tab_device}{
            margin-bottom: 10px;
        }
        @media #{$medium_device}{
            margin-bottom: 10px;
        }
    }
}
ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.mb_110{
    margin-bottom: 110px;
    @media #{$small_mobile}{
        margin-bottom: 220px;
    }
    
}
.mt_130{
    margin-top: 130px;
    @media #{$small_mobile}{
        margin-top: 70px;
    }
    @media #{$large_mobile}{
        margin-top: 70px;
    }
    @media #{$tab_device}{
        margin-top: 70px;
    }
    @media #{$medium_device}{
        margin-top: 70px;
    }
}

.pb_140{
    padding-bottom: 140px;
    @media #{$small_mobile}{
        padding-bottom: 70px;
    }
    @media #{$large_mobile}{
        padding-bottom: 70px;
    }
    @media #{$tab_device}{
        padding-bottom: 70px;
    }
    @media #{$medium_device}{
        padding-bottom: 80px;
    }
}
.mt_80{
    margin-top: 80px;
}
.padding_less_40{
    margin-bottom: -50px;
}
.z_index{
    z-index: 9 !important;
    position: relative;
}

@media #{$small_mobile}{
    .container-fluid{
        padding-right: 15px;
        padding-left: 15px;
    }
}
@media #{$extra_big_screen}{
    .container {
        max-width: 1170px;
    }
}
@media (max-width: 1200px) {
    [class*="hero-ani-"] {
        display: none !important;
    }
}

.custom-animation {
    backface-visibility: hidden;
    animation: jumping 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all .9s ease 5s;
    user-select: none;
}

.custom-animation2 {
    backface-visibility: hidden;
    animation: jumping2 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 1s ease 3s;
    user-select: none;
}

.custom-animation3 {
    backface-visibility: hidden;
    animation: jumping3 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 7s ease 2s;
    user-select: none;
}

.custom-animation4 {
    backface-visibility: hidden;
    animation: jumping4 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none;
}

.custom-animation10 {
    backface-visibility: hidden;
    animation: jumping10 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none;
}

.custom-animation11 {
    backface-visibility: hidden;
    animation: jumping11 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none;
}

@keyframes jumping {
    0% {
        transform: translateY(0px) translateX(0) rotate(0) scale(1);
        opacity: .8;
    }
    25% {
        transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(.8);
        opacity: .9;
    }
    50% {
        transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(.9);
        opacity: .8;
    }
    75% {
        transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(.75);
        opacity: .6;
    }
    85% {
        transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(.9);
        opacity: .7;
    }
    100% {
        transform: translateY(-15px) translateX(-15px) rotate(0) scale(.95);
        opacity: .85;
    }
}

@keyframes jumping2 {
    0% {
        transform: translateY(0px) translateX(0) rotate(0) scale(1);
        opacity: .5;
    }
    25% {
        transform: translateY(-30px) translateX(10px) rotate(20deg) scale(.8);
        opacity: .8;
    }
    50% {
        transform: translateY(15px) translateX(-15px) rotate(10deg) scale(.7);
        opacity: .8;
    }
    75% {
        transform: translateY(30px) translateX(20px) rotate(20deg) scale(.75);
        opacity: .7;
    }
    100% {
        transform: translateY(-15px) translateX(15px) rotate(0) scale(.75);
        opacity: .9;
    }
}

@keyframes jumping3 {
    0% {
        transform: translateY(10px) translateX(0) rotate(0) scale(1);
        opacity: .9;
    }
    20% {
        transform: translateY(20px) translateX(10px) rotate(-20deg) scale(.8);
        opacity: .8;
    }
    40% {
        transform: translateY(15px) translateX(-15px) rotate(10deg) scale(.75);
        opacity: .8;
    }
    40% {
        transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(.5);
        opacity: 1;
    }
    80% {
        transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(.75);
        opacity: .6;
    }
    100% {
        transform: translateY(15px) translateX(15px) rotate(0) scale(.95);
        opacity: .7;
    }
}

@keyframes jumping4 {
    0% {
        transform: translateY(-30px) translateX(20px) rotate(0) scale(1.2);
        opacity: .7;
    }
    25% {
        transform: translateY(-20px) translateX(10px) rotate(50deg) scale(.6);
        opacity: .8;
    }
    50% {
        transform: translateY(15px) translateX(-15px) rotate(20deg) scale(.5);
        opacity: .9;
    }
    75% {
        transform: translateY(30px) translateX(20px) rotate(50deg) scale(.75);
        opacity: .7;
    }
    100% {
        transform: translateY(-15px) translateX(15px) rotate(0) scale(.5);
        opacity: .9;
    }
}

@keyframes jumping10 {
    0% {
        transform: rotate(180deg);
        display: block;
    }
    100% {
        transform: rotate(30deg);
        display: block;
    }
}

@keyframes jumping11 {
    0% {
        transform: rotate(180deg) translate(-20px, 20px);
        display: block;
    }
    100% {
        transform: rotate(30deg) translate(0px, 0px);
        display: block;
    }
}



.hero-app-1 {
    position: absolute;
    left: 4%;
    top: 10%;
    @media #{$small_mobile}{
        display: none;
    }
    @media #{$large_mobile}{
        padding: 8px 25px;
    }
    @media #{$tab_device}{
        padding: 10px 25px;
    }
    @media #{$medium_device}{
        padding: 10px 25px;
    }
}

.hero-app-2 {
    position: absolute;
    left: 5%;
    bottom: 35%;
    @media #{$small_mobile}{
        bottom: 5%;
    }
    @media #{$large_mobile}{
        bottom: 20%;
    }
    @media #{$tab_device}{
        padding: 10px 25px;
    }
    @media #{$medium_device}{
        padding: 10px 25px;
    }
}

.hero-app-3 {
    position: absolute;
    top: 20%;
    left: 25%;
    @media #{$small_mobile}{
        left: 5%;
    }
    @media #{$large_mobile}{
        
    }
    @media #{$tab_device}{
        
    }
    @media #{$medium_device}{
        
    }
}

.hero-app-4 {
    position: absolute;
    bottom: 20%;
    left: 50%;
}

.hero-app-5 {
    position: absolute;
    bottom: 40%;
    left: 50%;
}

.hero-app-6 {
    position: absolute;
    right: 5%;
    top: 40%;
}

.hero-app-7 {
    position: absolute;
    top: 13%;
    left: 51%;
    @media #{$small_mobile}{
        
    }
    @media #{$large_mobile}{
        top: 5%;
        left: 73%;
    }
    @media #{$tab_device}{
        padding: 10px 25px;
    }
    @media #{$medium_device}{
        padding: 10px 25px;
    }
}

.hero-app-8 {
    position: absolute;
    left: 68%;
    top: 60%;
    @media #{$small_mobile}{
       top: 80%;            
    }
    @media #{$large_mobile}{
    }
    @media #{$tab_device}{
    }
    @media #{$medium_device}{
    }
}
/**************** common css end ****************/
