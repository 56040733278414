/**************** social contact part css start ****************/
.social_connect_part{
    padding-top: 130px;
    @media #{$small_mobile}{
        padding-top: 70px;
    }
    @media #{$large_mobile}{
        padding-top: 70px;
    }
    @media #{$tab_device}{
        padding-top: 70px;
    }
    @media #{$medium_device}{
        padding-top: 70px;
    }
    .container-fluid{
        padding-left: 0px;
        padding-right: 0px;
    }
    .social_connect{
        display: flex;
        justify-content: space-between;
        border-bottom: 10px solid $white_color;
    }
    .single-social_connect{
        width: 16%;
        float: left;
        &:hover{
            .social_connect_img:after{
                opacity: 0.5;
                transform: scale(1);
            }
            .social_connect_overlay span{
                visibility: visible;
                opacity: 1;
            }
        }
        .social_connect_img{
            position: relative;
            z-index: 1;
            
            &:after{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                content: "";
                background-color: $black_color;
                opacity: 0;
                z-index: 1;
                transform: scale(.5);
                @include transform_time(.5s);
            }
            img{
                width: 100%;
                height: auto;
                
            }
            &:hover{
                .social_connect_overlay{
                    span{
                        visibility: visible;
                    }
                }
            }
            .social_connect_overlay{
                span{
                    position: absolute;
                    text-align: center;
                    top: 45%;
                    left: 0;
                    right: 0;
                    z-index: 9;
                    color: $white_color;
                    visibility: hidden;
                    font-size: 30px;
                    @media #{$small_mobile}{
                        font-size: 14px;
                        top: 40%;
                    }
                    @media #{$large_mobile}{
                        font-size: 14px;
                        top: 40%;
                    }
                    @media #{$tab_device}{
                        font-size: 20px;
                        top: 40%;
                    }
                    @media #{$medium_device}{
                    
                    }
                }
            }
        }
    }
}