/**************** cta part css start ****************/
.cta_part{
    text-align: center;
    padding: 132px 0px 74px;
    @media #{$small_mobile}{
        padding: 70px 0px 40px;
    }
    @media #{$large_mobile}{
        padding: 70px 0px 40px;
    }
    @media #{$tab_device}{
        padding: 70px 0px 40px;
    }
    @media #{$medium_device}{
        padding: 80px 0px 40px;
    }
    .cta_part_iner{
        .cta_part_text{
            p{
                color: $btn_bg;
                text-transform: uppercase;
                margin-bottom: 10px;
                font-weight: 500;
            }
            h1{
                font-size: 42px;
                font-weight: 700;
                @media #{$small_mobile}{
                    font-size: 25px;
                }
                @media #{$large_mobile}{
                    font-size: 25px;
                }
                @media #{$tab_device}{
                    font-size: 25px;
                }
                @media #{$medium_device}{
                
                }
            }
        }
    }
}