$font_stack_1: 'Roboto', sans-serif;
$font_stack_2: 'Poppins', sans-serif;
$font_stack_3: 'Oswald', sans-serif;

$white_color: #fff;
$black_color: #000;
$menu_color: #2f373d;
$icon_color: #ff7e5f;




$btn_bg: #ff1481;
$btn_hover: #f5790b;
$section_bg: #f7f7f7;
$section_bg_1: #162b45;
$single_section_bg: #2e4158;
$heading_color: #112e41;
$heading_color2: #ff8b23;
$p_color: #666666;
$font_1: #666666;
$font_2: #646464;
$font_3: #7f7f7f;
$font_4: #8a8a8a;
$font_5: #999999;
$font_6: #666666;
$border_color: #fdcb9e;
$footer_bg: #303030;
$sidebar_bg: #fbf9ff;



$medium_device : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tab_device:'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 576px) and (max-width: 767px)';
$small_mobile:'(max-width: 576px)';
$xs_mobile:'(max-width: 420px)';
$sm_mobile:'only screen and (min-width: 421px) and (max-width: 575px)';
$big_screen:'only screen and (min-width: 1200px) and (max-width: 1440px)';
$extra_big_screen: 'only screen and (min-width: 1600px) and (max-width: 1920px)';
  
  
  