/**************** project_datails css start ****************/
.project_details{
    .project_details_content{
        padding: 40px 0px 0px;

        h3{
            font-size: 32px;
            font-weight: 600;
            line-height: 1.406;
            color: rgb(47, 55, 61);
            margin-bottom: 20px;
            @media #{$small_mobile}{
                font-size: 20px;
            }
            @media #{$large_mobile}{
                font-size: 25px;
            }
            @media #{$tab_device}{
                font-size: 28px;
            }
            @media #{$medium_device}{
                font-size: 30px;
            }
        }
        p{
            color: $font_1;
            font-weight: 400;
        }
        h4{
            color: $menu_color;
            font-size: 20px;
            font-weight: 600;
            margin: 25px 0px 25px;
            @media #{$small_mobile}{
                margin: 15px 0px 15px;
            }
            @media #{$large_mobile}{
                margin: 15px 0px 15px;
            }
            @media #{$tab_device}{
                margin: 15px 0px 15px;
            }
            @media #{$medium_device}{
            
            }
        }
    }
    .project_details_widget{
        padding: 40px 0px 0px;
        @media #{$small_mobile}{
            padding: 20px 0px 0px;
        }
        @media #{$large_mobile}{
            padding: 20px 0px 0px;
        }
        @media #{$tab_device}{
            padding: 20px 0px 0px;
        }
        @media #{$medium_device}{
            padding: 30px 0px 0px;
        }
        .single_project_details_widget{
            position: relative;
            padding-left: 36px;
            margin-bottom: 40px;
            @media #{$small_mobile}{
                margin-bottom: 20px;
            }
            @media #{$large_mobile}{
                margin-bottom: 20px;
            }
            @media #{$tab_device}{
                margin-bottom: 20px;
            }
            @media #{$medium_device}{
                margin-bottom: 20px;
            }
            span{
                color: #888888;
                font-size: 20px;
                position: absolute;
                left: 0;
                top: 5px;
            }
            h5{
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 1px;
            }
            h6{
                font-family: "Roboto",sans-serif;
                font-weight: 400;
                font-size: 14px;
            }
            p{
                font-weight: 300;
            }
        }
    }
}
.project_details_single{
    .single-home-blog{
        text-align: center;
    }
    .card-body{
        border: 0px solid transparent !important;
    }
    .section_tittle{
        margin-bottom: 50px;
        h2{
            text-align: left;
            font-size: 42px;
        }
    }
  

}
.next_icon img {
    width: 50px;
    position: absolute;
    right: 0;
    display: inline-block;
    top: 0;
}

.next_icon {
    position: relative;
    padding-right: 95px;
    text-transform: uppercase;
    color: #2f373d;
    padding-top: 15px;
    z-index: 1;
    margin-top: 60px;
    :after{
        position: absolute;
        content: "";
        left: 61px;
        top: 15px;
        width: 25px;
        height: 20px;
        background-color: rgb(249, 208, 227);
        z-index: -1;
    }
}

.next_icon a {
    color: #2f373d;
    font-family: $font_stack_3;
    font-weight: 500;
}
.pre_icon{
    position: relative;
    padding-left: 95px;
    text-transform: uppercase;
    color: #2f373d;
    padding-top: 15px;
    z-index: 1;
    margin-top: 60px;
    :after{
        position: absolute;
        content: "";
        left: 35px;
        top: 15px;
        width: 25px;
        height: 20px;
        background-color: rgb(249, 208, 227);
        z-index: -1;
    }
}
.pre_icon a {
    color: #2f373d;
    font-family: $font_stack_3;
    font-weight: 500;
}
.pre_icon img {
    width: 50px;
    position: absolute;
    left: 0;
    display: inline-block;
    top: 0;
}